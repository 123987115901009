@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  /* font-size: 16px; */
  /* font-size: 20px; */
  font-size: 20px;
}

body {
  margin: 0;
  font-family: Lato, 'Spoqa Han Sans JP', sans-serif !important;
}

input::placeholder {
  color: #afafaf;
}

.height-100 {
  height: 100%;
}

.text-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.object-fit-cover {
  object-fit: cover;
  max-width: none;
}

.select-input {
  -webkit-appearance: none;
  appearance: none;
}

.content-hidden {
  overflow: hidden !important;
}

.amplify-label {
  color: #1d1c1d !important;
}

.component-message-wrap .message-content p {
  font-size: 1rem;
}

.component-icon-wrap {
  font-size: 1rem !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font: inherit;
  margin: 0;
}

:root {
  font-family: 'Lato';
}

.search-input:focus,
.search-input:focus-visible,
.search-input:active {
  border-color: #afafaf !important;
  outline: none;
}
.search-input::placeholder {
  color: #afafaf;
}

.carousel ul.control-dots {
  text-align: unset;
  left: 6.25% !important;
  bottom: 2.5rem !important;
  margin: 0;
}

.carousel li.dot {
  width: 10px !important;
  height: 10px !important;
  margin: 0 !important;
  margin-right: 6px !important;
  background: #595758 !important;
}

.carousel li.dot.selected {
  background: #1c252c !important;
}

textarea {
  outline-color: #afafaf;
}

textarea::placeholder {
  color: #afafaf;
}

.disabledButton {
  color: #e5e5e5 !important;
  border: 1px solid #e5e5e5 !important;
  cursor: default !important;
}

#media-link-img {
  cursor: pointer;
}

#media-link-img > .hover-img {
  display: none;
}

#media-link-img:hover > .default-img {
  display: none;
}

#media-link-img:hover > .hover-img {
  display: initial;
}

/* range date picker */
.rmdp-container {
  position: absolute;
  width: 14rem;
  height: 2.1rem !important;
  top: 0;
}

.rmdp-container > input {
  position: absolute;
  top: 0;
  background: rgb(0, 0, 0, 0);
  width: 14rem;
  height: 2.1rem;
  border: 1px solid #afafaf;
  border-radius: 3px;
  text-align: center;
  z-index: 991;
  color: rgb(0, 0, 0, 0);
  cursor: pointer;
  margin: 0;
}
/* range date picker end */

.line-height-reset > * {
  line-height: initial;
}

/* antd-message start **/
.component-message-wrap {
  /* border: 1px solid #c4c4c4; */
  border-radius: 0 !important;
  /* box-shadow: 0 1px 1px #afafaf; */
  z-index: 991 !important;
  cursor: pointer;
  white-space: pre-wrap;
}

/* .component-message-wrap:active,
.component-message-wrap:visited {
  color: red;
  animation-name: message;
  animation-duration: 0 !important;
  animation-duration: 0 !important;
} */

.message-content {
  column-gap: 0.5rem !important;
}

.message-content i {
  margin-top: 0.1rem;
  margin-right: none !important;
  /* display: none !important; */
}
/* 
.message-content p::after {
  content: ' X';
} */

/* antd-message end **/

/* tooltip start **/
.tooltip {
  position: absolute;
  left: -1px;
  bottom: 1.7rem;
  display: none !important;
  z-index: 991;
  background: #f9f9f9;
  padding: 0.5rem;
  border: 1px solid #afafaf;
  border-radius: 3px;
}

.tooltip-trigger {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.tootip-trigger:hover .tooltip,
.tootip-trigger:active .tooltip {
  display: block !important;
}

.tooltip:before {
  left: 0% !important;
  border: solid transparent !important;
  content: '' !important;
  height: 0 !important;
  width: 0 !important;
  position: absolute !important;
  pointer-events: none !important;
}

.tooltip:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: #f9f9f9;
  width: 0.7rem !important;
  height: 0.7rem !important;
  border-color: #afafaf !important;
  border-width: 0 2px 1px 0 !important;
  border-radius: 1px !important;
  bottom: calc(0.7rem * -1.414 + 0.6rem) !important;
  left: 10px !important;
  z-index: 991;
}

.tooltip:focus,
.tooltip:active,
.tooltip:hover,
.tooltip:target,
.tooltip:focus-visible,
.tooltip:focus-within,
.tooltip:after {
  outline: 0 !important;
}

/* tooltip end **/

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* global **/
:is(.amplify-table) .amplify-table__th {
  padding-top: 0.35rem;
  padding-bottom: 0.3rem;
}

:is(.amplify-table) .amplify-table__td {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
/* global **/

:is(.landing-page, html) span {
  /* 1em = 20px */
  /* font-size: 20px;
  color: #1d1c1d; */
}

.landing-page-header-hidden {
  opacity: 0;
  transform: translateY(-5rem);
}

.landing-page-header-visible {
  opacity: 1;
  transform: translateY(0);
}

.landing-page-header-dark {
  background: #1c252c !important;
}

.landing-page-header-dark .landing-header-menu-group-3 span {
  color: #ffffff;
}

.landing-page-header-dark #log-button {
  background: #1c252c !important;
  border-color: #ffffff !important;
}

.landing-page-header-dark #log-button span {
  color: #ffffff !important;
}

.landing-page-header-dark #start-yejin-button {
  background: #ffffff !important;
  border-color: #ffffff !important;
}

.landing-page-header-dark #start-yejin-button span {
  color: #1d1c1d !important;
}

.landing-page-header-light {
  background: #ffffff;
}

/* pc landing page start **/
.landing-page {
  overflow-x: hidden;
}

/* pc landing page end **/

@media print {
  #prescription-print-area-content,
  #pr-print-area-content,
  #pdr-print-area-content {
    width: 100% !important;
    min-height: 250mm;
  }
  #prescription-print-area,
  #pr-print-area,
  #pdr-print-area {
    padding: 20px 40px !important;
  }

  .no-print {
    display: none !important;
  }
}

.landing-page-section-2-img-wrapper {
  text-align: center;
}

@media (max-width: 500px) {
  html {
    font-size: 7px;
  }

  /* 핸드폰 사용자를 위한 css */

  .too-small-for-mobile-level-1 {
    font-size: 2.5rem !important;
  }
  .too-small-for-mobile-level-2 {
    font-size: 1.8rem !important;
  }
  .too-small-for-mobile-level-3 {
    font-size: 2.2rem !important;
  }

  .landing-header-menu-group-3 {
    column-gap: 1.4rem !important;
  }

  .landing-page-section-2-imgs {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    row-gap: 4rem;
  }

  .landing-page-section-2-imgs > div {
    align-items: center;
    justify-content: center;
  }

  .landing-page-section-2-imgs img {
    max-width: 28.5rem !important;
  }
}

@media (min-width: 501px) and (max-width: 719px) {
  html {
    font-size: clamp(8px, 2vw, 10px) !important;
  }
}

@media (max-width: 719px) {
  /* 회원 가입 페이지  */
  #signup-wrapper {
    width: 60% !important;
  }
  #media-link-img > img {
    min-width: 30rem !important;
  }
  .modal-with-just-x {
    width: 96% !important;
    height: 54% !important;
  }
  .landing-page-normal-font {
    font-size: 1.2rem !important;
    line-height: 1.5rem !important;
  }
  .landing-header-menu-group-2 {
    column-gap: 2rem !important;
  }

  .landing-header-menu-group-3 {
    column-gap: 1.5rem !important;
  }

  :is(.landing-header-menu-group-2) span {
    font-size: 1.3rem !important;
  }
  .too-big-for-mobile-level-1 {
    font-size: 3rem !important;
  }

  /* 핸드폰 사용자를 위한 css */
  .landing-page-section-2 {
    padding-left: 3% !important;
    padding-right: 3% !important;
  }
  .landing-page-section-3 {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
  .too-big-for-mobile-level-1 {
    font-size: 3rem !important;
  }
  .too-big-for-mobile-level-2 {
    font-size: 2.15rem !important;
    letter-spacing: -0.02em !important;
  }
  .too-big-for-mobile-level-3 {
    font-size: 1.4rem !important;
    letter-spacing: -0.05em !important;
  }

  .too-small-for-mobile-level-2 {
    font-size: 1.8rem !important;
  }

  .landing-page-section-2 {
    row-gap: 3.25rem !important;
  }

  .landing-page-section-6,
  .landing-page-section-7 {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }

  .landing-page-section-9 {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }

  .landing-page-section-9-graph-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .landing-page-section-4,
  .landing-page-section-5,
  .landing-page-section-6,
  .landing-page-section-7 {
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    row-gap: 3rem !important;
  }

  .landing-page-section-4-text,
  .landing-page-section-5-text,
  .landing-page-section-6-text,
  .landing-page-section-7-text {
    text-align: center;
    align-items: center;
  }

  .landing-page-section-4-text-2,
  .landing-page-section-5-text-2 {
    text-align: center !important;
  }

  .landing-page-section-6-graph-wrapper,
  .landing-page-section-7-graph-wrapper {
    column-gap: 2rem !important;
  }

  .landing-page-FAQ-title {
    font-size: 1.7rem !important;
  }

  .landing-page-FAQ-content {
    font-size: 1.5rem !important;
  }

  .landing-page-FAQ-link {
    font-size: 1.5rem !important;
  }

  .one-line-under-720 {
    display: flex;
    justify-content: center;
    margin-top: 0 !important;
  }

  .multi-line-under-720 {
    display: flex;
    flex-direction: column;
  }

  .not-multi-line-under-720 {
    display: flex;
    flex-direction: row !important;
  }

  .landing-page-section-9-text {
    justify-content: space-between !important;
    column-gap: 1.5rem !important;
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .landing-page-section-9-text-2 {
    letter-spacing: -0.02em !important;
    line-height: 1.35em !important;
  }
}

.prescription-history-table,
.prescription-info-modal-table {
  width: 100%;
  border-top: 3px solid #e5e5e5;
}

.prescription-history-table th,
.prescription-info-modal-table th {
  border-right: 2px solid #f2f2f2;
  padding-left: 0.75rem;
  text-align: left;
  height: 2rem;
}
.prescription-history-table th:nth-child(3n - 1),
.prescription-history-table th:nth-child(3n - 2),
.prescription-history-table th:last-child,
.prescription-info-modal-table th:last-child,
.prescription-info-modal-table.herb-info-table th:nth-child(2n - 1) {
  border-right: none;
}

.prescription-history-table td,
.prescription-info-modal-table td {
  border: 1px solid #f2f2f2;
  padding-left: 0.75rem;
  text-align: left;
  height: 2rem;
}
.prescription-history-table td:last-child,
.prescription-info-modal-table td:last-child {
  border-right: none;
}
.prescription-history-table td:first-child,
.prescription-info-modal-table td:first-child {
  border-left: none;
}

/** react-star **/
.feedback-grade {
  display: flex;
  align-items: center;
  column-gap: 0.2rem;
  flex-shrink: 0;
}

.feedback-grade span {
  font-size: 1.05rem !important;
}
/** react-star end **/

.patient-history-dot {
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
}

.patient-info-table th,
.patient-info-table td,
.basic-setting-table th,
.basic-setting-table td {
  text-align: left;
  vertical-align: center;
  /* border: 1px solid #000; */
}
.patient-info-table span,
.basic-setting-table span {
  line-height: 1.2em;
  white-space: nowrap;
}
.patient-info-table td span,
.basic-setting-table td span {
  font-size: 1rem;
}
.patient-info-table tr td:first-child {
  padding-block: 0.5rem;
}
.patient-info-table tr td:first-child span,
.basic-setting-table tr td:first-child span {
  font-weight: 700;
}

.basic-setting-table tr {
  height: 2.5rem;
}

.list-page-common-table {
  border-top: 1px solid #d2d2d2;
  width: 100%;
}

.list-page-common-table th,
.list-page-common-table td {
  padding-inline: 1.3rem;
  vertical-align: middle;
}

.list-page-common-table thead tr {
  height: 2.1rem;
  background-color: #f9fbfc;
}

.list-page-common-table tbody tr:hover {
  background-color: #fcfcfc;
}

.list-page-common-table tbody tr {
  height: 3.5rem;
  border-bottom: 1px solid #e5e5e5;
}

.list-page-common-table span {
  font-size: 0.9rem;
  line-height: 1.2em;
  white-space: nowrap;
}

.list-page-common-table thead span {
  font-weight: 400;
}

.list-page-common-table tbody td {
  text-align: center;
}

.qr-clinic-list-table tbody td {
  padding-inline: 0.8rem !important;
}

.patient-list-page-table tr th:first-child,
.patient-list-page-table tr td:first-child {
  width: 5rem;
}
.patient-list-page-table tr th:nth-child(2),
.patient-list-page-table tr td:nth-child(2) {
  width: 6rem;
}
.patient-list-page-table tr th:nth-child(3),
.patient-list-page-table tr td:nth-child(3) {
  width: 4rem;
}
.patient-list-page-table tr th:nth-child(4),
.patient-list-page-table tr td:nth-child(4) {
  text-align: left;
  width: 8rem;
}

.patient-list-page-table tr th:nth-child(5),
.patient-list-page-table tr td:nth-child(5) {
  width: 8.5rem;
}
.patient-list-page-table tr th:nth-child(6),
.patient-list-page-table tr td:nth-child(6) {
  width: calc(100% - 31.5rem);
}

.treatment-list-page-table tr th:first-child,
.treatment-list-page-table tr td:first-child {
  width: 5rem;
}
.treatment-list-page-table tr th:nth-child(2),
.treatment-list-page-table tr td:nth-child(2) {
  width: 6rem;
}
.treatment-list-page-table tr th:nth-child(3),
.treatment-list-page-table tr td:nth-child(3) {
  width: 4rem;
}
.treatment-list-page-table tr th:nth-child(4),
.treatment-list-page-table tr td:nth-child(4) {
  width: 4rem;
}
.treatment-list-page-table tr th:nth-child(5),
.treatment-list-page-table tr td:nth-child(5) {
  width: 8.5rem;
}
.treatment-list-page-table tr th:nth-child(6),
.treatment-list-page-table tr td:nth-child(6) {
  text-align: left;
  width: 100%;
}
.treatment-list-page-table tr td:nth-child(6) div {
  height: 100%;
  min-height: 1.08rem;
  color: #afafaf;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* text align vertical center */
  -webkit-box-align: center;
  -webkit-box-pack: center;

  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
  vertical-align: middle;
}
.treatment-list-page-table tr td:nth-child(6) span {
  white-space: normal;
}

.treatment-list-page-table tr th:nth-child(7),
.treatment-list-page-table tr td:nth-child(7) {
  width: 17rem;
}

.role-list-table tbody tr {
  height: 4rem !important;
}

.role-list-table tr th:first-child,
.role-list-table tr td:first-child {
  width: 8rem;
}
.role-list-table tr th:nth-child(2),
.role-list-table tr td:nth-child(2) {
  width: 10rem;
}
.role-list-table tr th:nth-child(3),
.role-list-table tr td:nth-child(3) {
  width: 10rem;
}
.role-list-table tr th:nth-child(4),
.role-list-table tr td:nth-child(4) {
  width: 8rem;
}
.role-list-table tr th:last-child,
.role-list-table tr td:last-child {
  width: 2rem;
  text-align: right;
}

.signup-list-page-table thead tr {
  height: 1rem !important;
}

.signup-list-page-table tbody tr {
  height: 1rem;
  border-bottom: 1px solid #e5e5e5;
}

.my-prescription-table {
  border-top: 3px solid #e5e5e5;
}

.my-prescription-table thead tr {
  height: 2rem;
}

.my-prescription-table thead th {
  text-align: left;
}

.my-prescription-table span {
  white-space: nowrap;
}

.my-prescription-table thead th span {
  font-size: 0.9rem;
  font-weight: 700;
}

.my-prescription-table tbody tr {
  height: 3rem;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

.my-prescription-table tr th:first-child,
.my-prescription-table tr td:first-child {
  padding-left: 0.75rem;
}

.my-prescription-table tr th:last-child,
.my-prescription-table tr td:last-child {
  padding-right: 0.75rem;
}

.my-prescription-sub-table {
  width: 100%;
}

.my-prescription-sub-table thead tr {
  height: 2rem;
  border: none;
}

.my-prescription-sub-table thead th {
  text-align: left;
}

.my-prescription-sub-table span {
  white-space: nowrap;
}

.my-prescription-sub-table thead th span {
  font-size: 0.8rem;
  font-weight: 700;
}

.my-prescription-sub-table tbody tr {
  height: 2rem;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}

.my-prescription-sub-table tbody tr:last-child {
  border-bottom: none;
}

.my-prescription-sub-table tr th:first-child,
.my-prescription-sub-table tr td:first-child {
  padding-left: 0.75rem;
}

.my-prescription-sub-table tr th:last-child,
.my-prescription-sub-table tr td:last-child {
  padding-right: 0.75rem;
}

/* sign-up-confirm-table */
.sign-up-confirm-table {
  width: 100%;
}

.sign-up-confirm-table tr {
  height: 1.8rem;
}

.sign-up-confirm-table tr td span {
  font-size: 1rem;
  line-height: 1.2em;
  letter-spacing: 0.03em;
  white-space: nowrap;
}

.sign-up-confirm-table tr td:first-child {
  width: 6rem;
}
.sign-up-confirm-table tr td:first-child span {
  font-weight: 700;
}

/* sign-up-confirm-table end */

.treatment-mode-option:hover {
  background-color: #f5f5f5;
}

.youtube-tutorial-component {
  display: flex;
  background-color: #39576f;
  padding: 1.2rem 1rem;
  display: flex;
  position: relative; /* 상대 위치 지정 */
  z-index: 1;
}

.youtube-tutorial-component::before {
  content: '';
  position: absolute;
  left: -0.3rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 0.4rem solid transparent;
  border-right: 0.3rem solid #39576f;
  border-bottom: 0.4rem solid transparent;
}

.faq-button:hover {
  border-bottom: 1px solid #505a6d !important;
}

.treatment-detail-button:hover {
  background: #e5e9f1 !important;
}

.sidebar-button:hover {
  background: #e5e9f1 !important;
}

.my-prescription-plus-minus-wrapper .black-plus-minus {
  display: block;
  opacity: 1;
}
.my-prescription-plus-minus-wrapper .blue-plus-minus {
  display: none;
  opacity: 0;
}

.my-prescription-plus-minus-wrapper:hover .black-plus-minus {
  display: none;
  opacity: 0;
}
.my-prescription-plus-minus-wrapper:hover .blue-plus-minus {
  display: block;
  opacity: 1;
}

@media (max-width: 1024px) {
  .landing-page-section-1 {
    height: 47.5rem !important;
  }
  /* #landing-page-circle-graph-1 {
    width: 12rem !important;
  } */
}

@media (max-width: 1919px) {
  .multi-line-under-1920 {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 720px) and (max-width: 1024px) {
  html {
    font-size: clamp(10px, 1.38vw, 14px);
  }
  #signup-wrapper {
    width: 45% !important;
  }
  .landing-header-menu-group-3 {
    column-gap: 1.7rem;
  }
}

@media (min-width: 1024px) and (max-width: 1920px) {
  html {
    font-size: clamp(14px, 1.36vw, 20px);
  }
  /* 회원 가입 페이지  */
  #signup-wrapper {
    width: 36% !important;
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 20px;
  }
}
